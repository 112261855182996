(function($) {

    $('.contact-form').submit(function (e) {
        e.preventDefault();
        var $form = $(this);

        $form.find('input[type="submit"]').prop('disabled', true);

        var _name            = $form.find('input[name="name"]').val();
        var _email           = $form.find('input[name="email"]').val();
        var _verify_email    = $form.find('input[name="verify-email"]').val();
        var _website         = $form.find('input[name="website"]').val();
        var _is_amazon       = ($form.find('input[name="is-amazon"]').prop('checked') === true) ? 'yes' : 'no';
        var _amazon_order_id = $form.find('input[name="amazon-order-id"]').val();
        var _subject         = $form.find('select[name="subject"]').val();
        var _message         = $form.find('textarea[name="message"]').val();
        var _honeypot        = $form.find('input[name="honeypot"]').val();
        var _captcha_token   = e.target.elements['g-recaptcha-response'].value;

        if (!_name || !_email || !_subject || !_message) {
            alert('Oops! Please fill out all fields.');

            $form.find('input[type="submit"]').prop('disabled', false);

            return false;
        }

        if (!_captcha_token) {
            alert('Oops! Please complete the captcha.');

            $form.find('input[type="submit"]').prop('disabled', false);

            return false;
        }

        if (_email.indexOf('@', 0) === -1) {
            alert('Oops! Please provide a valid email address so we can get back to you.');

            $form.find('input[type="submit"]').prop('disabled', false);

            return false;
        }

        if ((!_verify_email) || (_email !== _verify_email)) {
            alert('Oops! Looks like your emails don\'t match.');

            $form.find('input[type="submit"]').prop('disabled', false);

            return false;
        }

        $.post('/onnit/req/contact.php', {
            name: _name
            , email: _email
            , subject: _subject
            , website: _website
            , is_amazon: _is_amazon
            , amazon_order_id: _amazon_order_id
            , message: _message
            , honeypot: _honeypot
            , captcha_token: _captcha_token
        }, function (ret) {
            if (ret === '0') {
                alert('Hmm... something went wrong. Try again in a few minutes or email us at help@onnit.com .');
            } else if (ret === 'invalidCaptcha') {
                alert('Hmm... something went wrong. The human test failed. Please try again.');
            } else {
                $form.fadeOut(400, function () {
                    $('<div class="font-size-md lh3" style="display:none;">Thank you for contacting us! We will get back to you as soon as possible.</div>').insertBefore($form).fadeIn(200);
                });
            }
        });
    });

})(jQuery);
